* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.theme-btn {
  background-color: #497ca7 !important;
  color: white !important;
}
.bg-gray {
  background-color: #e7e2e2;
}
.modal-dialog {
  margin-top: 10% !important;
}
.loaderimg {
  display: flex;
  justify-content: center;
  align-items: center;
}
.splash {
  min-height: 99vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}
.splash img {
  left: 0px;
  top: 0px;
  width: 50%;
  height: 50%;
  z-index: 9999;
}
.jobcard {
  border-left: 8px solid #497ca7;
  border-radius: 10px;
  box-shadow: 8px 10px 17px -7px grey;
}
.searchbar {
  border: 2px solid #497ca7;
  border-radius: 10px;
  padding: 15px;
  width: 100%;
}
.searchbtn {
  background-color: #497ca7;
  border: 2px solid #497ca7;
  color: white;
  border-radius: 10px;
  padding: 15px;
  width: 100%;
}

/* loader animation */

.mydiv {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.cssanimation,
.cssanimation span {
  animation-duration: 5s;
  animation-fill-mode: both;
}

.cssanimation img {
  display: inline-block;
}

.fadeOutLeft {
  animation-name: fadeOutLeft;
}
@keyframes fadeOutLeft {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translateX(-100%);
  }
}

/* .bgnurse{
  background: url(../src/Images/about/nurse.jpg);
  background-position: center;
  background-size: 100%;
  opacity: 0.6;
  filter: blur(4px);
} */

.bgnurse {
  background-image: url(../src/Images/about/nurse.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  object-fit: cover;
  display: flex;
  align-items: center;
  opacity: 0.6;

}

.box_shadow{
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  transition: 0.5s;
}
.box_shadow:hover{
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  cursor: pointer;
}